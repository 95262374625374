import { QueryHookOptions, QueryResult } from '@apollo/client';

import {
  GetDocusignCompletedContent,
  GetDocusignCompletedContentVariables,
} from './__generated__/GetDocusignCompletedContent';
import * as queries from './query.gql';

import { useContentstackQuery } from '~/utils';

export const useGetDocusignCompletedContent = (
  options?: QueryHookOptions<GetDocusignCompletedContent, GetDocusignCompletedContentVariables>,
): QueryResult<GetDocusignCompletedContent, GetDocusignCompletedContentVariables> => {
  return useContentstackQuery(queries.GetDocusignCompletedContent, options);
};
