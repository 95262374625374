import { FinancialAccountType } from '~/__generated__';
import { ContentOptions } from '~/utils';

export interface ExtractedBrokerageData {
  accountBalance: number;
  accountNumber: number;
  accountType: FinancialAccountType;
  currencyCode: string;
  financialInstitution: string;
}

export type Props = {
  content?: ConnectBrokerageAccountModalContent;
  contentOptions: ContentOptions;
  data?: ConnectBrokerageAccountAutoFetchingData;
  enabledAccountTypes: FinancialAccountType[];
  isSubmitted: boolean;
  onAccountChange?: (acc: AccountDetails) => void;
  onSuccessfulSubmitCallback?: ({
    accountBalance,
    accountNumber,
    accountType,
    financialInstitution,
  }: ExtractedBrokerageData) => void;
};

export interface ConnectBrokerageAccountModalContent {
  accountTypeLabels: { label: string; value: FinancialAccountType }[];
  ctas: {
    cancel: string;
    connectAccount: string;
    dndFileuploader: string;
    next: string;
  };
  errors: {
    accountType: string;
    balance: string;
    financialInstitution: string;
  };
  labels: {
    accountNumber: string;
    accountType: string;
    balance: string;
    brokerageAccountFormHeading: string;
    financialInstitution: string;
    passwordHeading: string;
    passwordLabel: string;
    selectAccount: string;
    title: string;
  };
}

export enum ConnectBrokerageAccountSteps {
  BROKERAGE_ACCOUNT_FORM = 'BROKERAGE_ACCOUNT_FORM',
  DOCUMENT_UPLOADER = 'DOCUMENT_UPLOADER',
  ENTER_PASSWORD = 'ENTER_PASSWORD',
}

export interface CustomFile extends File {
  filePassword?: string;
}

export enum SupportedFileTypes {
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PDF = 'application/pdf',
  PNG = 'image/png',
}

export interface AccountDetails {
  accountName?: string | null;
  accountNumber?: string | null;
  accountType?: FinancialAccountType | null;
  balance?: string | null;
  currency?: string | null;
  financialInstitution?: string | null;
}

export interface ConnectBrokerageAccountAutoFetchingData {
  accountDetails?: AccountDetails[];
  filePath?: string;
}

export enum NotificationMessageType {
  AUTO_FETCH_FAILED = 'auto_fetch_failed',
  AUTO_FETCH_SUCCESS = 'auto_fetch_success',
  FILE_TYPE = 'file_type_error',
  MAX_FILE = 'max_file',
  MAX_FILE_SIZE = 'file_size_error',
  PASSWORD_MISSING = 'incorrect_password',
  SUCCESS_UPLOAD = 'successfully_upload_document',
  WRONG_PDF_PASSWORD = 'incorrect_password',
}

export interface NotificationMessage {
  config?: { [key: string]: any };
  message: string;
  severity: 'success' | 'error';
}
