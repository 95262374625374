import { Style } from '~/components/pdfs/styles';

export interface AccountDetailsHeaderContent {
  accessibility: {
    accountDetailsMenuLabel: string;
    // Optional as Account Details V1 does not have due to Contentstack limit, can make mandatory after V1 is removed
    accountDetailsMenuPreLabel?: string;
  };
  header: {
    accountNumberFormat: string;
    activeAccountLabel: string;
    addFundsCta: string;
    bankAccountCta: string;
    belowAccountMinimumLabel: string;
    closedAccountLabel: string;
    journalingCta: string;
    raiseCashCta: string;
    // Optional as Account Details V1 does not have due to Contentstack limit, can make mandatory after V1 is removed
    title?: string;
    viewingDropdownOptions: {
      quarterlyPerformance: string;
    };
  };
}

export interface DownloadableQprProps {
  partnerStyles: Style;
  partyIdFA: string | null;
}

// Enum is in this case as there is already contentstack used having this case
// https://app.contentstack.com/#!/stack/blt7ba9b75c585c2d4e/content-type/account_details_tabs/en-us/entry/blta334dcd7dda6be3a/edit?branch=main
export enum AccountDetailsTabsEnum {
  activity = 'activity',
  documents = 'documents',
  overview = 'overview',
  suspensions = 'suspensions',
}
