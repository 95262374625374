import { MutationTuple } from '@apollo/client';

import { CreatePlaidAccessToken, CreatePlaidAccessTokenVariables } from './__generated__/CreatePlaidAccessToken';
import { CreatePlaidLinkToken, CreatePlaidLinkTokenVariables } from './__generated__/CreatePlaidLinkToken';
import { RefreshPlaidAccessToken, RefreshPlaidAccessTokenVariables } from './__generated__/RefreshPlaidAccessToken';
import * as mutations from './mutation.gql';

import { MutationHookOptions, useSymphonyMutation } from '~/utils';

export const useCreatePlaidLinkToken = (
  options?: MutationHookOptions<CreatePlaidLinkToken, CreatePlaidLinkTokenVariables>,
): MutationTuple<CreatePlaidLinkToken, CreatePlaidLinkTokenVariables> => {
  return useSymphonyMutation(mutations.CreatePlaidLinkToken, options);
};

export const useCreatePlaidAccessToken = (
  options?: MutationHookOptions<CreatePlaidAccessToken, CreatePlaidAccessTokenVariables>,
): MutationTuple<CreatePlaidAccessToken, CreatePlaidAccessTokenVariables> => {
  return useSymphonyMutation(mutations.CreatePlaidAccessToken, options);
};

export const useRefreshPlaidAccessToken = (
  options?: MutationHookOptions<RefreshPlaidAccessToken, RefreshPlaidAccessTokenVariables>,
): MutationTuple<RefreshPlaidAccessToken, RefreshPlaidAccessTokenVariables> => {
  return useSymphonyMutation(mutations.RefreshPlaidAccessToken, options);
};
