import { visuallyHidden } from '@mui/utils';
import React from 'react';

import { Alert, Skeleton } from '~/components';
import { ContentOptions } from '~/utils';

interface AlertAndLoadingProps {
  ariaLabel: string;
  contentOptions: ContentOptions;
  error?: Error;
  loading: boolean;
}

export const AlertAndLoading: React.FC<AlertAndLoadingProps> = ({ ariaLabel, error, loading, contentOptions }) => {
  return (
    <>
      {error && <Alert contentOptions={contentOptions} error={error} severity="error" />}
      {loading && (
        <>
          <progress aria-label={ariaLabel} id="progress-skeleton" style={visuallyHidden} />
          <div aria-busy="true" aria-describedby="progress-skeleton">
            <Skeleton aria-hidden="true" />
            <Skeleton aria-hidden="true" />
            <Skeleton aria-hidden="true" />
          </div>
        </>
      )}
    </>
  );
};
