import { ServerError, ServerParseError } from '@apollo/client';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as React from 'react';

import { Collapse, Typography } from '..';

import { ApolloError, Environment, GraphQLError } from '~/utils';
import { SymphonyError } from '~/utils/symphony';

type networkErrorType = Error | ServerError | ServerParseError;
const isServerError = (error: networkErrorType): error is ServerError => {
  return 'result' in error;
};
const isServerParseError = (error: networkErrorType): error is ServerParseError => {
  return 'bodyText' in error;
};
interface NetworkErrorProps {
  error: networkErrorType;
  networkErrorMessage?: string;
}
const NetworkErrorOutput: React.FC<NetworkErrorProps> = ({ error, networkErrorMessage }) => {
  const output =
    !isServerParseError(error) && !isServerError(error) ? (
      <Typography data-qa="error-details-apollo-network-error-basic">{error.toString()}</Typography>
    ) : (
      <>
        <Typography data-qa="error-details-apollo-network-error">{error.toString()}</Typography>
        <Typography data-qa="error-details-apollo-network-error-status">{error.statusCode}</Typography>
        <Typography data-qa="error-details-apollo-network-error-response">{JSON.stringify(error.response)}</Typography>
        {isServerError(error) && (
          <Typography data-qa="error-details-apollo-network-error-result">{JSON.stringify(error.result)}</Typography>
        )}
      </>
    );

  return (
    <>
      {output}
      <Typography>{networkErrorMessage}</Typography>
    </>
  );
};

type errorType = string | Error;
const isString = (error: errorType): error is string => {
  return typeof error === 'string' || error instanceof String;
};
const isApolloError = (error: errorType): error is ApolloError => {
  return !isString(error) && 'graphQLErrors' in (error as ApolloError);
};
const isSymphonyError = (error: GraphQLError | SymphonyError): error is SymphonyError => {
  return 'code' in error || 'correlationId' in error;
};
export interface Props {
  additionalDetails?: React.ReactNode;
  dataQa?: string;
  error?: errorType;
  networkErrorMessage?: string;
}
export const ErrorDetails: React.FC<Props> = ({
  additionalDetails,
  error,
  dataQa = 'error-details',
  networkErrorMessage,
}) => {
  const [collapse, setCollapse] = React.useState(false);

  const iconProps = {
    sx: { color: '#007EAB', fontSize: 'medium' },
    onClick: () => setCollapse(prevCollapse => !prevCollapse),
    'data-qa': 'error-details-toggle',
  };

  if (process.env.NODE_ENV === Environment.Prod) {
    return <></>;
  } else {
    return (
      <span data-qa={dataQa} style={{ wordBreak: 'break-word' }}>
        {collapse ? <ExpandLessIcon {...iconProps} /> : <ExpandMoreIcon {...iconProps} />}
        <Collapse in={collapse} sx={{ textAlign: 'left' }}>
          {error &&
            (isApolloError(error) ? (
              <>
                <Typography data-qa="error-details-apollo-message">{error.message}</Typography>
                {error.networkError && (
                  <NetworkErrorOutput error={error.networkError} networkErrorMessage={networkErrorMessage} />
                )}
                {error.graphQLErrors &&
                  error.graphQLErrors.map((e: GraphQLError | SymphonyError, i: number) => (
                    <React.Fragment key={i}>
                      {/* If message exists and its not the same message as error.message */}
                      {e.message && error.message !== e.message && (
                        <Typography data-qa="error-details-apollo-detailed-message">{e.message}</Typography>
                      )}
                      {isSymphonyError(e) && (
                        <Typography data-qa="error-details-apollo-detailed-code">{e.code}</Typography>
                      )}
                      {isSymphonyError(e) && (
                        <Typography data-qa="error-details-apollo-detailed-correlationId">{e.correlationId}</Typography>
                      )}
                    </React.Fragment>
                  ))}
              </>
            ) : (
              <Typography data-qa="error-details-error">{isString(error) ? error : error.toString()}</Typography>
            ))}
          {additionalDetails}
        </Collapse>
      </span>
    );
  }
};
