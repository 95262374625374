import { compareDesc, differenceInDays } from 'date-fns';

import { toSymphonyDate } from '../symphony';

import { ContactType } from '~/__generated__';
import { Party, PartyContact, PartyPerson } from '~/containers/AccountPerformance/hooks/useGetPerformancePdfData';
import { GetClientInfo } from '~/hooks';
import {
  GetClientInfo_client_party,
  GetClientInfo_client_party_partyContacts,
  GetClientInfo_client_party_partyPerson,
} from '~/hooks/client/symphony/__generated__/GetClientInfo';

export const getUserName = (clientInfo?: GetClientInfo): string => {
  return `${clientInfo?.client?.party?.partyPerson?.givenName ?? ''} ${
    clientInfo?.client?.party?.partyPerson?.familyName ?? ''
  }`;
};

/**
 * {GetClientInfo_client_party_partyPerson | null | undefined} partyPerson - Party person object
 * @returns {string | undefined} Full name for the party
 */
export const getFullName = (
  partyPerson: GetClientInfo_client_party_partyPerson | PartyPerson | null | undefined,
): string | undefined => {
  if (partyPerson) {
    return partyPerson.displayName
      ? partyPerson.displayName
      : partyPerson.givenName && partyPerson.familyName
      ? `${partyPerson.givenName} ${partyPerson.familyName}`
      : undefined;
  } else {
    return undefined;
  }
};

/**
 * @param {GetClientInfo_client_party | Party | null | undefined} party - Party object containing party contacts
 * @returns {string} Email for the party
 */
export const getEmail = (party: GetClientInfo_client_party | Party | null | undefined): string => {
  const partyContacts: Array<PartyContact | GetClientInfo_client_party_partyContacts> = party?.partyContacts ?? [];
  const primaryEmail = partyContacts.find(contact => contact.isPrimary && contact.type === ContactType.EMAIL);
  if (primaryEmail) {
    return primaryEmail.contact;
  }
  return partyContacts.find(contact => contact.type === ContactType.EMAIL)?.contact ?? '';
};

/**
 * @param {GetClientInfo_client_party | Party | null | undefined} party - Party object containing party contacts
 * @returns {string} Phone number for the party
 */
export const getPhone = (party: GetClientInfo_client_party | Party | null | undefined): string => {
  const partyContacts: Array<PartyContact | GetClientInfo_client_party_partyContacts> = party?.partyContacts ?? [];
  const phoneContactTypes = [ContactType.MOBILE, ContactType.LANDLINE];
  const primaryPhone = partyContacts.find(contact => contact.isPrimary && phoneContactTypes.includes(contact.type));
  if (primaryPhone) {
    return primaryPhone.contact;
  }
  return partyContacts.find(contact => phoneContactTypes.includes(contact.type))?.contact ?? '';
};

export const isStringEmpty = (inputValue: string): boolean => {
  // Trims spaces from both ends
  if (inputValue && inputValue.trim().length === 0) {
    return true;
  }
  return false;
};

export const isDateWeekend = (date: Date) => date.getDay() === 6 || date.getDay() === 0;

export const shouldDisableWeekends = (date: Date, disableWeekends: boolean): boolean =>
  disableWeekends ? isDateWeekend(date) : false;

export const shouldDisableAfterXDays = (date: Date, disableAfterXDays: number | null): boolean =>
  disableAfterXDays ? differenceInDays(date, new Date()) >= disableAfterXDays : false;

export const shouldDisableRestCurrentYearForRetirementAccounts = (
  date: Date,
  disableRestOfCurrentYearForRetirementAccounts: boolean,
  isRetirementAccountType?: boolean,
) =>
  disableRestOfCurrentYearForRetirementAccounts && isRetirementAccountType
    ? date.getFullYear() !== new Date().getFullYear()
    : false;

export const shouldDisableMarketHolidays = (date: Date, marketHolidays: string[], disableMarketHolidays: boolean) =>
  disableMarketHolidays ? marketHolidays.includes(toSymphonyDate(date as Date)) : false;

export const shouldDisableDateAfterMaxWithdrawalDate = (
  date: Date,
  maxWithdrawalDate: Date | null,
  disableDateAfterMaxWithdrawalDate: boolean,
) => disableDateAfterMaxWithdrawalDate && !!maxWithdrawalDate && date > maxWithdrawalDate;

export const shouldDisableDateTillAsSoonAsPossibleDate = (
  date: Date,
  asSoonAsPossibleDate: Date,
  disableDateTillAsSoonAsPossibleDate: boolean,
) => disableDateTillAsSoonAsPossibleDate && compareDesc(date, asSoonAsPossibleDate) > 0;
