import { ComponentProps } from 'react';

import { DateRange, DateRangePicker, Dropdown } from '~/components';

export interface DateRangeOptionConfig extends ComponentProps<typeof Dropdown> {
  maxDate?: Date;
  minDate?: Date;
}

export interface DateFilterConfig {
  customDateRange?: ComponentProps<typeof DateRangePicker>;
  dateRangeOption?: DateRangeOptionConfig;
  yearOption?: ComponentProps<typeof Dropdown>;
}

export interface DateFilterValues {
  dateRange?: DateRange;
  dateRangeOption?: DateRangeOptions;
  yearOption?: number;
}

export interface DateFilterData {
  config: DateFilterConfig;
  onCustomDateRangeChange?: (dateRange?: DateRange) => void;
  onDateRangeOptionChange?: (option: string) => void;
  onYearOptionChange?: (option: number) => void;
  values: DateFilterValues;
}

export enum DateRangeOptions {
  Custom = 'Custom',
  Last30Days = 'Last30Days',
  Last60Days = 'Last60Days',
  Last90Days = 'Last90Days',
  YTD = 'YTD',
}
