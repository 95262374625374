import { Divider as MuiDivider } from '@mui/material';
import React, { ComponentProps } from 'react';

//  Empty space is added as Voice over in safari announces empty tags as a new line.
//  display: none to MuiDivider-wrapper class as it creates space between dividers.

export const Divider: React.FC<ComponentProps<typeof MuiDivider>> = ({ children, sx, ...rest }) => (
  <MuiDivider
    {...rest}
    aria-hidden={!children}
    sx={{
      ...sx,
      ...(!children && {
        '.MuiDivider-wrapper': {
          display: 'none',
        },
      }),
    }}
  >
    {children ?? ' '}
  </MuiDivider>
);
