import * as React from 'react';

import { GetErrorMessageImage } from './image';

import { Box, Button, ErrorDetails, RteContent, Skeleton, Stack, Typography, useTheme } from '~/components';
import { useGetErrorMessagesData } from '~/hooks/error-messages';
import { ContentOptions, SfTheme } from '~/utils';

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  error?: string | Error;
  isAuthorizationError?: boolean;
  message?: string;
  suggestion?: string;
}
export const ErrorComponent: React.FC<Props> = ({
  contentOptions,
  dataQa = 'error',
  error,
  isAuthorizationError,
  message,
  suggestion,
}) => {
  const { data: errorMessagesData, loading: errorMessagesDataLoading } = useGetErrorMessagesData(contentOptions);
  const {
    sfErrorComponent: { styles: sfErrorComponentStyles },
  } = useTheme<SfTheme>();
  if (errorMessagesDataLoading) {
    return <Skeleton sx={{ display: 'inline-block' }} width={400} />;
  }
  return (
    <Stack data-qa={dataQa} justifyContent="center" sx={{ ...sfErrorComponentStyles }}>
      <GetErrorMessageImage centered errorImage={errorMessagesData?.error_image_url} />

      <Typography align="center" variant="h3">
        {message ||
          (isAuthorizationError
            ? errorMessagesData?.error_component_unauthorised_message
            : errorMessagesData?.error_component_message) ||
          'Sorry, it looks like something went wrong.'}
      </Typography>

      <Box maxWidth="600px" mx="auto" p={3} sx={{ textAlign: 'center' }}>
        <RteContent
          component="div"
          data={
            suggestion ||
            (isAuthorizationError
              ? errorMessagesData?.error_component_unauthorised_sub_message
              : errorMessagesData?.error_component_sub_message) ||
            'We are looking into the issue, but in the meantime please try refreshing your page as this might help resolve the error.'
          }
        />
        {error && <ErrorDetails error={error} networkErrorMessage={errorMessagesData?.error_details_network_message} />}
        {isAuthorizationError
          ? errorMessagesData?.redirect_cta_unauthorised?.label && (
              <Button href={errorMessagesData.redirect_cta_unauthorised.link} sx={{ marginTop: 3 }} variant="contained">
                {errorMessagesData.redirect_cta_unauthorised.label}
              </Button>
            )
          : errorMessagesData?.redirect_cta?.label && (
              <Button href={errorMessagesData.redirect_cta.link} sx={{ marginTop: 3 }} variant="contained">
                {errorMessagesData.redirect_cta.label}
              </Button>
            )}
      </Box>
    </Stack>
  );
};
