import CheckIcon from '@mui/icons-material/Check';
import React, { FC } from 'react';

import { MenuItem } from '.';

import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, useTheme } from '~/components';

interface Props {
  dataQa?: string;
  items: MenuItem[];
}

export const MenuList: FC<Props> = ({ dataQa = 'drawer-menu-list', items }) => {
  const {
    sfDrawer: { styles: sfDrawerStyles },
  } = useTheme();

  return (
    <List data-qa={dataQa} sx={{ py: 0 }}>
      {items.map((item, index) => (
        <>
          {item.type === 'list-item-header' ? (
            <ListSubheader key={index}>{item.label}</ListSubheader>
          ) : (
            <ListItem disablePadding divider key={index}>
              <ListItemButton
                {...item}
                disabled={item.isDisabled}
                sx={{ alignItems: item.description ? 'baseline' : 'center', py: 2 }}
              >
                {item.isChecked && (
                  <CheckIcon fontSize="small" sx={{ color: sfDrawerStyles.selectedItemColor, pr: 1, height: 16 }} />
                )}
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText
                  primary={item.label}
                  secondary={item.description}
                  sx={[
                    { '.MuiListItemText-secondary': { color: 'text.primary' }, my: 0 },
                    !!item.isChecked && { color: sfDrawerStyles.selectedItemColor },
                  ]}
                />
              </ListItemButton>
            </ListItem>
          )}
        </>
      ))}
    </List>
  );
};
