import { DocusignDaProCompletedContent } from '.';
import { GetDocusignCompletedContent } from './contentstack/__generated__/GetDocusignCompletedContent';

import { findFieldValue, getImageAssetUrl } from '~/utils';

export const getDocusignDAProCompletedContent = (data?: GetDocusignCompletedContent): DocusignDaProCompletedContent => {
  const content = data?.all_docusign?.items?.[0];
  const textContent = content?.fields?.text ?? [];
  return {
    assistant: {
      // Todo - Updating content stack for assistant https://sigfig.atlassian.net/browse/ANR-11091
      signedMessage: content?.fa_signed?.heading || '',
      signedImage: getImageAssetUrl(content?.fa_signed?.imageConnection),
      signedPrincipalSubHeading: content?.fa_signed?.principal_sub_heading || '',
      signedSubHeading: content?.fa_signed?.sub_heading || '',
      signedSecondaryCtaLabel: content?.fa_signed?.secondary_cta_label || '',
      signedPrimaryCtaLabel: content?.fa_signed?.primary_cta_label || '',
      declinedMessage: findFieldValue(textContent, 'assistantDeclinedHeading'),
      declinedImage: getImageAssetUrl(content?.fa_declined?.imageConnection),
      clientDeclinedMessage: content?.fa_view_client_declined?.heading || '',
      clientDeclinedImage: getImageAssetUrl(content?.fa_view_client_declined?.imageConnection),
      bankAccountAssociationSubHeading: findFieldValue(textContent, 'bankAccountAssociationFaSubHeading'),
      bankAccountAssociationMessage: findFieldValue(textContent, 'bankAccountAssociationFaMessage'),
      bankAccountAssociationPrimaryCta: findFieldValue(textContent, 'bankAccountAssociationPrimaryCta'),
    },
    faPage: {
      faSignedMessage: content?.fa_signed?.heading || '',
      faSignedImage: getImageAssetUrl(content?.fa_signed?.imageConnection),
      faSignedPrincipalSubHeading: content?.fa_signed?.principal_sub_heading || '',
      faSignedSubHeading: content?.fa_signed?.sub_heading || '',
      faSignedSecondaryCtaLabel: content?.fa_signed?.secondary_cta_label || '',
      faSignedPrimaryCtaLabel: content?.fa_signed?.primary_cta_label || '',
      faDeclinedMessage: content?.fa_declined?.heading || '',
      faDeclinedImage: getImageAssetUrl(content?.fa_declined?.imageConnection),
      clientDeclinedMessage: content?.fa_view_client_declined?.heading || '',
      clientDeclinedImage: getImageAssetUrl(content?.fa_view_client_declined?.imageConnection),
      bankAccountAssociationSubHeading: findFieldValue(textContent, 'bankAccountAssociationFaSubHeading'),
      bankAccountAssociationMessage: findFieldValue(textContent, 'bankAccountAssociationFaMessage'),
      bankAccountAssociationPrimaryCta: findFieldValue(textContent, 'bankAccountAssociationPrimaryCta'),
    },
    clientPage: {
      clientSignedMessage: content?.client_signed?.heading || '',
      clientSignedImage: getImageAssetUrl(content?.client_signed?.imageConnection),
      clientSignedSubHeading: content?.client_signed?.sub_heading || '',
      clientDeclinedMessage: content?.client_declined?.heading || '',
      clientDeclinedImage: getImageAssetUrl(content?.client_declined?.imageConnection),
      bankAccountAssociationFailedMessage: findFieldValue(textContent, 'bankAccountAssociationFailedHeading'),
    },
    common: {
      docusignStatusOtherMessage: content?.docusign_status_other?.heading || '',
      docusignStatusOtherImage: getImageAssetUrl(content?.docusign_status_other?.imageConnection),
      docusignStatusOtherSubHeading: content?.docusign_status_other?.sub_heading || '',
      docusignStatusOtherClientSubHeading: content?.docusign_status_other?.client_sub_heading || '',
      docusignStatusOtherFASubHeading: content?.docusign_status_other?.fa_sub_heading || '',
    },
  };
};
