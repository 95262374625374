/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountHolderType {
  AUTHORIZED_INDIVIDUAL = "AUTHORIZED_INDIVIDUAL",
  BENEFICIAL_OWNER = "BENEFICIAL_OWNER",
  CONTROL_PERSON = "CONTROL_PERSON",
}

export enum AccountRestrictionType {
  BILLING = "BILLING",
  REGULATORY_T = "REGULATORY_T",
}

export enum AddressType {
  HOME = "HOME",
  MAILING = "MAILING",
  OFFICE = "OFFICE",
  OTHER = "OTHER",
}

export enum AssetDepositField {
  CREATED = "CREATED",
}

export enum AssociatedEntityType {
  ASSET_DEPOSIT = "ASSET_DEPOSIT",
  BANK_ACCOUNT_ASSOCIATION = "BANK_ACCOUNT_ASSOCIATION",
  ENTITY_UPDATE_WORKFLOW = "ENTITY_UPDATE_WORKFLOW",
  PLAN_UPDATE_WORKFLOW = "PLAN_UPDATE_WORKFLOW",
}

export enum AssetTransferTransactionType {
  FIRST_PARTY = 'FIRST_PARTY',
  THIRD_PARTY = 'THIRD_PARTY',
  THIRD_PARTY_COMMON = 'THIRD_PARTY_COMMON',
}

export enum BalanceType {
  AVAILABLE = "AVAILABLE",
  CASH = "CASH",
  CASH_AVAILABLE = "CASH_AVAILABLE",
  CASH_TOTAL = "CASH_TOTAL",
  MARGIN = "MARGIN",
  MARGIN_ACCOUNT_BUYING_POWER = "MARGIN_ACCOUNT_BUYING_POWER",
  POSITIONS_TOTAL = "POSITIONS_TOTAL",
  SHORT = "SHORT",
  TOTAL_ACCOUNT = "TOTAL_ACCOUNT",
  TOTAL_TRADE_DATE = "TOTAL_TRADE_DATE",
  TYPE_5 = "TYPE_5",
  UNKNOWN_BALANCE = "UNKNOWN_BALANCE",
  WITHDRAWAL_LIMIT = "WITHDRAWAL_LIMIT",
}

export enum BankAccountType {
  CHEQUING = "CHEQUING",
  SAVINGS = "SAVINGS",
}

export enum BillingRateTargetType {
  ADVISOR = "ADVISOR",
  PLATFORM = "PLATFORM",
  PROVIDER = "PROVIDER",
  UNKNOWN = "UNKNOWN",
}

export enum BillingRateType {
  ANNUALIZED_ABSOLUTE = "ANNUALIZED_ABSOLUTE",
  ANNUALIZED_NON_MARGINAL_TIERED = "ANNUALIZED_NON_MARGINAL_TIERED",
  ANNUALIZED_PERCENTAGE = "ANNUALIZED_PERCENTAGE",
}

export enum BlockType {
  HARD = "HARD",
  SOFT = "SOFT",
}

export enum BusinessEntityRoleType {
  ACCREDITED_INVESTOR = "ACCREDITED_INVESTOR",
  US_REGISTERED_BROKER_DEALER = "US_REGISTERED_BROKER_DEALER",
  US_REGISTERED_INVESTMENT_ADVISOR = "US_REGISTERED_INVESTMENT_ADVISOR",
  US_REGISTERED_INVESTMENT_COMPANY = "US_REGISTERED_INVESTMENT_COMPANY",
}

export enum BusinessEntityType {
  COMPANY = "COMPANY",
  HOUSEHOLD = "HOUSEHOLD",
  TRUST = "TRUST",
  UNKNOWN = "UNKNOWN",
}

export enum CashTransferInstanceStatus {
  CANCELLED = "CANCELLED",
  CASH_GENERATED = "CASH_GENERATED",
  COMPLETED = "COMPLETED",
  PENDING = "PENDING",
  TRADES_SUBMITTED = "TRADES_SUBMITTED",
  UNKNOWN = "UNKNOWN",
}

export enum ClientReviewStatus {
  BAD_EMAIL = "BAD_EMAIL",
  DUE = "DUE",
  NEW = "NEW",
  OVERDUE = "OVERDUE",
  PENDING = "PENDING",
  UNKNOWN = "UNKNOWN",
  UP_TO_DATE = "UP_TO_DATE",
}

export enum CloseAccountStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  IN_PROCESS_AT_BROKER = "IN_PROCESS_AT_BROKER",
  PENDING = "PENDING",
  REACHING_OUT_TO_CLIENT = "REACHING_OUT_TO_CLIENT",
  READY_TO_TRADE = "READY_TO_TRADE",
  TRADES_SUBMITTED = "TRADES_SUBMITTED",
}

export enum CloseReason {
  ACCOUNT_HOLDER_HAS_PASSED_AWAY = "ACCOUNT_HOLDER_HAS_PASSED_AWAY",
  BELOW_TECHNICAL_MINIMUM_BALANCE = "BELOW_TECHNICAL_MINIMUM_BALANCE",
  CHANGING_TO_A_DIFFERENT_INVESTMENT_SOLUTION = "CHANGING_TO_A_DIFFERENT_INVESTMENT_SOLUTION",
  FEES_TOO_HIGH = "FEES_TOO_HIGH",
  I_AM_REALLOCATING_MY_ASSETS = "I_AM_REALLOCATING_MY_ASSETS",
  I_AM_REBALANCING_MY_ASSETS = "I_AM_REBALANCING_MY_ASSETS",
  MONEY_NEEDED_FOR_PURCHASE = "MONEY_NEEDED_FOR_PURCHASE",
  MOVING_MY_INVESTMENTS_TO_ANOTHER_FIRM = "MOVING_MY_INVESTMENTS_TO_ANOTHER_FIRM",
  MOVING_TO_NEW_ADVISOR = "MOVING_TO_NEW_ADVISOR",
  NEED_THE_FUNDS_IN_THE_ACCOUNT = "NEED_THE_FUNDS_IN_THE_ACCOUNT",
  OTHER = "OTHER",
  PERFORMANCE_NOT_AS_EXPECTED = "PERFORMANCE_NOT_AS_EXPECTED",
  PREFER_A_BROKERAGE_ACCOUNT = "PREFER_A_BROKERAGE_ACCOUNT",
  PRODUCT_CONFUSING = "PRODUCT_CONFUSING",
  PRODUCT_NOT_AS_EXPECTED = "PRODUCT_NOT_AS_EXPECTED",
  RECONSIDERING_MY_INVESTMENTS_DUE_TO_THE_CURRENT_MARKET_ENVIRONMENT = "RECONSIDERING_MY_INVESTMENTS_DUE_TO_THE_CURRENT_MARKET_ENVIRONMENT",
  RETURNS_NOT_AS_EXPECTED = "RETURNS_NOT_AS_EXPECTED",
  TECH_OR_SIGNUP_ISSUES = "TECH_OR_SIGNUP_ISSUES",
  UNDERGOING_A_DIVORCE_AND_REQUIRE_DIRECT_ACCESS_TO_ASSETS = "UNDERGOING_A_DIVORCE_AND_REQUIRE_DIRECT_ACCESS_TO_ASSETS",
  WANT_MORE_DIVERSIFICATION = "WANT_MORE_DIVERSIFICATION",
  WANT_TO_MANAGE_INVESTMENTS_MYSELF = "WANT_TO_MANAGE_INVESTMENTS_MYSELF",
}

export enum CloseWorkflowField {
  CREATED = "CREATED",
}

export enum CompareTag {
  ONBOARDING = "ONBOARDING",
  RCE = "RCE",
}

export enum ConditionSetOperationType {
  ADD = "ADD",
  DIVIDE = "DIVIDE",
  MULTIPLY = "MULTIPLY",
  SUBTRACT = "SUBTRACT",
}

export enum ConditionType {
  AND = "AND",
  OR = "OR",
}

export enum ContactType {
  DAYTIME_PHONE = "DAYTIME_PHONE",
  DIGITAL = "DIGITAL",
  EMAIL = "EMAIL",
  GENERIC_PHONE = "GENERIC_PHONE",
  INTERNATIONAL_PHONE = "INTERNATIONAL_PHONE",
  LANDLINE = "LANDLINE",
  MOBILE = "MOBILE",
  TOLL_FREE = "TOLL_FREE",
  UNKNOWN = "UNKNOWN",
}

export enum ControlType {
  DIRECTOR = "DIRECTOR",
  POLICY_MAKING_EXECUTIVE = "POLICY_MAKING_EXECUTIVE",
  SHAREHOLDER_10 = "SHAREHOLDER_10",
}

export enum CreatedByRole {
  ADVISOR = "ADVISOR",
  CLIENT = "CLIENT",
}

/**
 * A list of valid types for creating an account
 */
export enum CreationType {
  FULL_ACCOUNT_TRANSFER = "FULL_ACCOUNT_TRANSFER",
  NEW_ACCOUNT = "NEW_ACCOUNT",
  PARTIAL_ACCOUNT_TRANSFER = "PARTIAL_ACCOUNT_TRANSFER",
}

/**
 * Defines the data type expected from collected data point
 */
export enum DataPointType {
  BOOLEAN = "BOOLEAN",
  CURRENCY = "CURRENCY",
  INTEGER = "INTEGER",
  MULTIPLE_OPTION = "MULTIPLE_OPTION",
  SINGLE_OPTION = "SINGLE_OPTION",
}

export enum DocumentCategoryEnum {
  OTHERS = "OTHERS",
  STATEMENTS = "STATEMENTS",
  TAX_REPORTS = "TAX_REPORTS",
  TRADE_CONFIRMATIONS = "TRADE_CONFIRMATIONS",
}

export enum EmploymentStatus {
  EMPLOYED = "EMPLOYED",
  HOME_MAKER = "HOME_MAKER",
  RETIRED = "RETIRED",
  SELF_EMPLOYED = "SELF_EMPLOYED",
  STUDENT = "STUDENT",
  UNEMPLOYED = "UNEMPLOYED",
  UNKNOWN = "UNKNOWN",
}

export enum EntityProfileType {
  ENTITY = "ENTITY",
  STAKEHOLDER_CONTACT = "STAKEHOLDER_CONTACT",
  STAKEHOLDER_ENTITY = "STAKEHOLDER_ENTITY",
}

export enum EntityType {
  ASSET_DEPOSIT = "ASSET_DEPOSIT",
  BANK_ACCOUNT = "BANK_ACCOUNT",
  BANK_ACCOUNT_ASSOCIATION = "BANK_ACCOUNT_ASSOCIATION",
  CASH_DEPOSIT = "CASH_DEPOSIT",
  CASH_WITHDRAWAL = "CASH_WITHDRAWAL",
  COPILOT_PORTFOLIO = "COPILOT_PORTFOLIO",
  EXTERNAL_REQUEST = "EXTERNAL_REQUEST",
  INTERNAL_REQUEST = "INTERNAL_REQUEST",
  RECURRING_CASH_DEPOSIT = "RECURRING_CASH_DEPOSIT",
  RECURRING_CASH_WITHDRAWAL = "RECURRING_CASH_WITHDRAWAL",
}

export enum Field {
  AMOUNT = "AMOUNT",
  CREATED = "CREATED",
  DATE_ADDED = "DATE_ADDED",
  DATE_REMOVED = "DATE_REMOVED",
  SCHEDULED = "SCHEDULED",
  SETTLEMENT_DATE = "SETTLEMENT_DATE",
  TRANSACTION_DATE = "TRANSACTION_DATE",
}

export enum FinancialAccountAssociationVerificationRequestSortField {
  CREATED_ON = "CREATED_ON",
}

export enum FinancialAccountAssociationVerificationStatus {
  ACCEPTED = "ACCEPTED",
  IN_REVIEW = "IN_REVIEW",
  NEEDS_DOCUSIGN = "NEEDS_DOCUSIGN",
  REJECTED = "REJECTED",
  UNKNOWN = "UNKNOWN",
}

/**
 * The source where data is obtained
 */
export enum FinancialAccountSource {
  EXTERNAL = "EXTERNAL",
  MANUAL = "MANUAL",
  PARTNER_INTERNAL = "PARTNER_INTERNAL",
  PLAID = "PLAID",
  SYNCED = "SYNCED",
}

export enum FinancialAccountStatus {
  ABANDONED = "ABANDONED",
  ACTIVE = "ACTIVE",
  ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED = "ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED",
  CLOSED = "CLOSED",
  LEGAL_DOCUMENTS_PREPARED = "LEGAL_DOCUMENTS_PREPARED",
  LEGAL_DOCUMENTS_SIGNATURE_FAILED = "LEGAL_DOCUMENTS_SIGNATURE_FAILED",
  LEGAL_DOCUMENTS_SIGNED = "LEGAL_DOCUMENTS_SIGNED",
  LEGAL_DOCUMENTS_SUBMITTED = "LEGAL_DOCUMENTS_SUBMITTED",
  NEW = "NEW",
  NOT_STARTED = "NOT_STARTED",
  PARTIAL = "PARTIAL",
  PENDING_CLOSED = "PENDING_CLOSED",
  UNKNOWN_FINANCIAL_ACCOUNT_STATUS = "UNKNOWN_FINANCIAL_ACCOUNT_STATUS",
}

export enum FinancialAccountTransactionType {
  ADVISORY_FEE = "ADVISORY_FEE",
  BOND_OPTION_TRADE = "BOND_OPTION_TRADE",
  CAPITAL_GAINS = "CAPITAL_GAINS",
  CAPITAL_GAINS_REINVESTED = "CAPITAL_GAINS_REINVESTED",
  DIVIDEND_RECEIVED = "DIVIDEND_RECEIVED",
  DIVIDEND_REINVESTED = "DIVIDEND_REINVESTED",
  FUNDS_TRANSFER = "FUNDS_TRANSFER",
  IGNORE = "IGNORE",
  INTEREST_RECEIVED = "INTEREST_RECEIVED",
  INTEREST_REINVESTED = "INTEREST_REINVESTED",
  OTHER = "OTHER",
  REINVESTMENT = "REINVESTMENT",
  RETIREMENT_CONTRIBUTION = "RETIREMENT_CONTRIBUTION",
  SECURITY_NAME_CHANGE = "SECURITY_NAME_CHANGE",
  TRADE = "TRADE",
  UNKNOWN = "UNKNOWN",
}

export enum FinancialAccountType {
  ANNUITY = "ANNUITY",
  BENEFICIARY_ROTH_IRA_MINOR_CUSTODIAN = "BENEFICIARY_ROTH_IRA_MINOR_CUSTODIAN",
  BENEFICIARY_ROTH_IRA_TRUST = "BENEFICIARY_ROTH_IRA_TRUST",
  BENEFICIARY_TRADITIONAL_IRA_MINOR_CUSTODIAN = "BENEFICIARY_TRADITIONAL_IRA_MINOR_CUSTODIAN",
  BENEFICIARY_TRADITIONAL_IRA_TRUST = "BENEFICIARY_TRADITIONAL_IRA_TRUST",
  BROKERAGE = "BROKERAGE",
  BROKERAGE_CASH = "BROKERAGE_CASH",
  BROKERAGE_MARGIN = "BROKERAGE_MARGIN",
  BROKERAGE_OPTION = "BROKERAGE_OPTION",
  BUSINESS = "BUSINESS",
  CHARITY = "CHARITY",
  CHEQUING = "CHEQUING",
  CODA_SEP = "CODA_SEP",
  CONVERSION = "CONVERSION",
  CORPORATE = "CORPORATE",
  CORPORATION = "CORPORATION",
  COVERDELL_EDUCATION_SAVINGS_PLAN = "COVERDELL_EDUCATION_SAVINGS_PLAN",
  CUSTODIAL = "CUSTODIAL",
  CUSTODIAL_ROLLOVER_IRA = "CUSTODIAL_ROLLOVER_IRA",
  CUSTODIAL_ROTH_IRA = "CUSTODIAL_ROTH_IRA",
  CUSTODIAL_TRADITIONAL_IRA = "CUSTODIAL_TRADITIONAL_IRA",
  DEFINED_BENEFIT_PENSION = "DEFINED_BENEFIT_PENSION",
  EMPLOYEE_STOCK_PURCHASE_PLAN = "EMPLOYEE_STOCK_PURCHASE_PLAN",
  ESA = "ESA",
  ESTATE = "ESTATE",
  FHS = "FHS",
  FIVE_TWO_NINE = "FIVE_TWO_NINE",
  FOUR_FIVE_SEVEN_B = "FOUR_FIVE_SEVEN_B",
  FOUR_ZERO_ONE_A = "FOUR_ZERO_ONE_A",
  FOUR_ZERO_ONE_K = "FOUR_ZERO_ONE_K",
  FOUR_ZERO_THREE_B = "FOUR_ZERO_THREE_B",
  INDIVIDUAL = "INDIVIDUAL",
  INHERITED_IRA = "INHERITED_IRA",
  INHERITED_ROTH_IRA = "INHERITED_ROTH_IRA",
  INHERITED_TRADITIONAL_IRA = "INHERITED_TRADITIONAL_IRA",
  INVESTMENT = "INVESTMENT",
  IRA = "IRA",
  JOINT = "JOINT",
  JOINT_TENANTS_BY_ENTIRETY = "JOINT_TENANTS_BY_ENTIRETY",
  JOINT_TENANTS_IN_COMMON = "JOINT_TENANTS_IN_COMMON",
  KEOGH = "KEOGH",
  LIE = "LIE",
  LIR = "LIR",
  LIS = "LIS",
  LLC = "LLC",
  LRI = "LRI",
  MONEY_MARKET = "MONEY_MARKET",
  NORMAL = "NORMAL",
  NRSPR = "NRSPR",
  OTHER = "OTHER",
  PARTNERSHIP = "PARTNERSHIP",
  PRI = "PRI",
  RDS = "RDS",
  REF = "REF",
  REI = "REI",
  RIS = "RIS",
  RLI = "RLI",
  RMA_DOMESTIC = "RMA_DOMESTIC",
  ROLLOVER_IRA = "ROLLOVER_IRA",
  ROTH_401K = "ROTH_401K",
  ROTH_BDA = "ROTH_BDA",
  ROTH_IRA = "ROTH_IRA",
  RRI = "RRI",
  RRS = "RRS",
  RSL = "RSL",
  RSS = "RSS",
  SAVINGS = "SAVINGS",
  SEP = "SEP",
  SEP_IRA = "SEP_IRA",
  SIMPLE = "SIMPLE",
  SIMPLE_IRA = "SIMPLE_IRA",
  SOLE_PROPRIETORSHIP = "SOLE_PROPRIETORSHIP",
  TFS = "TFS",
  TRADITIONAL_IRA = "TRADITIONAL_IRA",
  TRADITIONAL_IRA_BDA = "TRADITIONAL_IRA_BDA",
  TRUST = "TRUST",
  TRUST_UNDER_AGREEMENT = "TRUST_UNDER_AGREEMENT",
  TRUST_UNDER_WILL = "TRUST_UNDER_WILL",
  UGMA = "UGMA",
  UNAVAILABLE = "UNAVAILABLE",
  UNKNOWN_FINANCIAL_ACCOUNT_TYPE = "UNKNOWN_FINANCIAL_ACCOUNT_TYPE",
  UTMA = "UTMA",
  _401K = "_401K",
}

export enum FlagEntity {
  ASSET_DEPOSIT = "ASSET_DEPOSIT",
  CASH_DEPOSIT = "CASH_DEPOSIT",
  CASH_WITHDRAWAL = "CASH_WITHDRAWAL",
  CLOSE_WORKFLOW = "CLOSE_WORKFLOW",
  FUNDING_REVIEW = "FUNDING_REVIEW",
  RECURRING_CASH_DEPOSIT = "RECURRING_CASH_DEPOSIT",
  RECURRING_CASH_WITHDRAWAL = "RECURRING_CASH_WITHDRAWAL",
}

export enum FlagReason {
  API_FAILURE = "API_FAILURE",
  HIGH_TRANSACTION_VALUE = "HIGH_TRANSACTION_VALUE",
  OTHER = "OTHER",
  OVERDUE = "OVERDUE",
  POSSIBLE_DUPLICATE = "POSSIBLE_DUPLICATE",
  REJECTED_BANK_ACCOUNT_ASSOCIATION = "REJECTED_BANK_ACCOUNT_ASSOCIATION",
}

export enum FlagStatus {
  OPEN = "OPEN",
  RESOLVED = "RESOLVED",
}

export enum FlowType {
  ONBOARDING = "ONBOARDING",
  RETAKE = "RETAKE",
}

export enum FundType {
  ANNUITY = "ANNUITY",
  ASSET = "ASSET",
  CASH = "CASH",
}

export enum FundingReviewSortField {
  ACTIVATED_AT = "ACTIVATED_AT",
}

export enum FundingReviewStatus {
  BELOW_MINIMUM = "BELOW_MINIMUM",
  FUNDED = "FUNDED",
  NEEDS_REVIEW = "NEEDS_REVIEW",
}

export enum FundingSourceType {
  ANNUITY = "ANNUITY",
  BANK_ACCOUNT_CHECKING = "BANK_ACCOUNT_CHECKING",
  BANK_ACCOUNT_SAVINGS = "BANK_ACCOUNT_SAVINGS",
  BROKERAGE_ACCOUNT = "BROKERAGE_ACCOUNT",
  CASH = "CASH",
  CHECK = "CHECK",
  EMPLOYER_SPONSORED_PLAN = "EMPLOYER_SPONSORED_PLAN",
  EXCHANGE = "EXCHANGE",
  OTHER_BROKERAGE_ACCOUNT = "OTHER_BROKERAGE_ACCOUNT",
  ROLLOVER_401K = "ROLLOVER_401K",
  SAME_BROKERAGE_ACCOUNT = "SAME_BROKERAGE_ACCOUNT",
  UNKNOWN = "UNKNOWN",
}

export enum IdentifierType {
  DRIVING_LICENSE = "DRIVING_LICENSE",
  GOVERNMENT = "GOVERNMENT",
  OTHER = "OTHER",
  PASSPORT = "PASSPORT",
  SSN = "SSN",
  STATE_LOCAL = "STATE_LOCAL",
  TIN = "TIN",
  UNKNOWN = "UNKNOWN",
  VISA = "VISA",
}

export enum InterestFrequency {
  LONG_TERM = "LONG_TERM",
  SHORT_TERM = "SHORT_TERM",
}

export enum InvestmentPurposeTypes {
  OTHER = "OTHER",
  RETIREMENT = "RETIREMENT",
}

/**
 * A list of supported languages
 */
export enum LanguageType {
  ENGLISH = "ENGLISH",
  FRENCH = "FRENCH",
}

export enum LegalDocumentStatus {
  DECLINED = "DECLINED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  PENDING_PRIMARY = "PENDING_PRIMARY",
  PENDING_SECONDARY = "PENDING_SECONDARY",
  SUCCEEDED = "SUCCEEDED",
}

export enum ManagedProductLegalDocumentType {
  ASSET_TRANSFER_ADDENDUM = "ASSET_TRANSFER_ADDENDUM",
  ASSET_TRANSFER_AUTHORIZATION = "ASSET_TRANSFER_AUTHORIZATION",
  BANK_SWEEP_DISCLOSURE = "BANK_SWEEP_DISCLOSURE",
  DIRECT_ROLLOVER_AUTHORIZATION = "DIRECT_ROLLOVER_AUTHORIZATION",
  E_DELIVERY_ACCEPTANCE = "E_DELIVERY_ACCEPTANCE",
  FIDUCIARY_ACKNOWLEDGEMENT = "FIDUCIARY_ACKNOWLEDGEMENT",
  FORM_ADV = "FORM_ADV",
  INVESTMENT_ADVISORY_AGREEMENT = "INVESTMENT_ADVISORY_AGREEMENT",
  INVESTMENT_MANAGEMENT_AGREEMENT = "INVESTMENT_MANAGEMENT_AGREEMENT",
  IRA_APPLICATION = "IRA_APPLICATION",
  MANAGED_AUTHORIZATION = "MANAGED_AUTHORIZATION",
  MOVE_MONEY = "MOVE_MONEY",
  NEW_ACCOUNT = "NEW_ACCOUNT",
  OTHER = "OTHER",
  PARTIAL_TRANSFER = "PARTIAL_TRANSFER",
  PRIVACY_DISCLOSURE = "PRIVACY_DISCLOSURE",
  RELATIONSHIP_SUMMARY = "RELATIONSHIP_SUMMARY",
  ROTH_IRA_APPLICATION = "ROTH_IRA_APPLICATION",
  TERMS_OF_SERVICE = "TERMS_OF_SERVICE",
  TRUSTEE_CERTIFICATION_OF_INVESTMENT_POWERS = "TRUSTEE_CERTIFICATION_OF_INVESTMENT_POWERS",
  UNKNOWN = "UNKNOWN",
}

export enum ManagedProductStatus {
  ABANDONED = "ABANDONED",
  ACTIVE = "ACTIVE",
  ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED = "ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED",
  CLOSED = "CLOSED",
  LEGAL_DOCUMENTS_PREPARED = "LEGAL_DOCUMENTS_PREPARED",
  LEGAL_DOCUMENTS_SIGNATURE_FAILED = "LEGAL_DOCUMENTS_SIGNATURE_FAILED",
  LEGAL_DOCUMENTS_SIGNED = "LEGAL_DOCUMENTS_SIGNED",
  LEGAL_DOCUMENTS_SUBMITTED = "LEGAL_DOCUMENTS_SUBMITTED",
  NEW = "NEW",
  NOT_STARTED = "NOT_STARTED",
  PARTIAL = "PARTIAL",
  PENDING_CLOSED = "PENDING_CLOSED",
  UNKNOWN_FINANCIAL_ACCOUNT_STATUS = "UNKNOWN_FINANCIAL_ACCOUNT_STATUS",
}

export enum ManagedProductType {
  DIGITAL_ADVICE = "DIGITAL_ADVICE",
  DIGITAL_ADVICE_PRO = "DIGITAL_ADVICE_PRO",
}

export enum ManagedProductsField {
  CREATED = "CREATED",
}

export enum MaritalStatus {
  DIVORCED = "DIVORCED",
  DOMESTIC_PARTNER = "DOMESTIC_PARTNER",
  MARRIED = "MARRIED",
  SEPARATED = "SEPARATED",
  SINGLE = "SINGLE",
  UNKNOWN = "UNKNOWN",
  WIDOWED = "WIDOWED",
}

export enum MultiCashTransferType {
  CASH_DEPOSIT = "CASH_DEPOSIT",
  CASH_WITHDRAWAL = "CASH_WITHDRAWAL",
  RECURRING_CASH_DEPOSIT = "RECURRING_CASH_DEPOSIT",
  RECURRING_CASH_WITHDRAWAL = "RECURRING_CASH_WITHDRAWAL",
}

export enum OnboardingStates {
  ASSET_CONSOLIDATION = "ASSET_CONSOLIDATION",
  FUNDING = "FUNDING",
  GOALS = "GOALS",
  INVESTMENT_SWITCH = "INVESTMENT_SWITCH",
  MADLIBS = "MADLIBS",
  PAPERWORK = "PAPERWORK",
  PLAN = "PLAN",
  PLAYBACK = "PLAYBACK",
  PORTFOLIO_SELECTION = "PORTFOLIO_SELECTION",
  RTQ = "RTQ",
  RTQ_RESULT = "RTQ_RESULT",
  SUITABILITY = "SUITABILITY",
  SUITABILITY_RESULT = "SUITABILITY_RESULT",
  UNKNOWN = "UNKNOWN",
}

export enum OneTimePasswordRole {
  PLAID_CUSTOMER = "PLAID_CUSTOMER",
}

export enum OrderSubset {
  A_C_SHARE = "A_C_SHARE",
  A_SHARE = "A_SHARE",
  C_SHARE = "C_SHARE",
  MADLIBS = "MADLIBS",
  PORTFOLIO_COMPARE_RTQ = "PORTFOLIO_COMPARE_RTQ",
  RETAKE_RTQ = "RETAKE_RTQ",
  RTQ = "RTQ",
  UNKNOWN = "UNKNOWN",
}

export enum OrderType {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export enum PaperworkType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  UNKNOWN = "UNKNOWN",
}

export enum PartyType {
  BUSINESS_ENTITY = "BUSINESS_ENTITY",
  COMPANY = "COMPANY",
  FINANCIAL_INSTITUTION = "FINANCIAL_INSTITUTION",
  PERSON = "PERSON",
  UNKNOWN_PARTY = "UNKNOWN_PARTY",
}

export enum PendingRmdStatus {
  ACTION_REQUIRED = "ACTION_REQUIRED",
  NO_PENDING_RMD = "NO_PENDING_RMD",
  REVIEW_REQUIRED = "REVIEW_REQUIRED",
}

export enum PerformanceMethodTypes {
  FROM_ACTIVITY = "FROM_ACTIVITY",
  FROM_END_OF_DAY_VALUES = "FROM_END_OF_DAY_VALUES",
}

export enum PersonType {
  CLIENT = "CLIENT",
  DEFAULT = "DEFAULT",
  EMPLOYEE = "EMPLOYEE",
}

export enum PlaidEventAction {
  LOGIN = "LOGIN",
  RE_AUTHENTICATION = "RE_AUTHENTICATION",
}

export enum PlaidProduct {
  AUTH = "AUTH",
  INVESTMENTS = "INVESTMENTS",
}

export enum PlanUpdateWorkflowStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  DOCS_PREPARED = "DOCS_PREPARED",
  DOCS_SIGNED = "DOCS_SIGNED",
  PENDING = "PENDING",
  SIGNATURE_FAILED = "SIGNATURE_FAILED",
}

export enum PortfolioGrade {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  F = "F",
  UNKNOWN = "UNKNOWN",
}

export enum ProcessingStatusTypes {
  PROCESSING_ANALYZING = "PROCESSING_ANALYZING",
  PROCESSING_CONNECTING = "PROCESSING_CONNECTING",
  PROCESSING_DOWNLOADING = "PROCESSING_DOWNLOADING",
  PROCESSING_FINDING = "PROCESSING_FINDING",
  PROCESSING_NEW = "PROCESSING_NEW",
  PROCESSING_QUEUED = "PROCESSING_QUEUED",
  SUCCESS = "SUCCESS",
}

export enum QuantityType {
  AVAILABLE = "AVAILABLE",
  LONG = "LONG",
  SHORT = "SHORT",
  TOTAL = "TOTAL",
  UNKNOWN_QUANTITY = "UNKNOWN_QUANTITY",
}

export enum QuestionnaireEntityType {
  ASSET_FUNDING_OPTION = "ASSET_FUNDING_OPTION",
  MANAGED_PRODUCT = "MANAGED_PRODUCT",
}

export enum QuestionnaireFilterTypes {
  LATEST = "LATEST",
  ONBOARDING_COMPLETED = "ONBOARDING_COMPLETED",
  RTQ_COMPLETED = "RTQ_COMPLETED",
}

export enum QuestionnaireType {
  ONBOARDING = "ONBOARDING",
  ONBOARDING_V2 = "ONBOARDING_V2",
  SUITABILITY = "SUITABILITY",
}

export enum RateUnitType {
  DAY = "DAY",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export enum RecommendationType {
  RISK_SCORE = "RISK_SCORE",
  SUITABILITY = "SUITABILITY",
}

export enum RegulatoryRelationship {
  RELATED_PERSON = "RELATED_PERSON",
  SELF_OR_ACCOUNT_OWNER = "SELF_OR_ACCOUNT_OWNER",
}

export enum RelationshipName {
  ADVISOR = "ADVISOR",
  AUTHORIZED_INDIVIDUAL = "AUTHORIZED_INDIVIDUAL",
  BENEFICIAL_OWNER = "BENEFICIAL_OWNER",
  BENEFICIARY = "BENEFICIARY",
  BENEFICIARY_PRIMARY = "BENEFICIARY_PRIMARY",
  BRANCH_ASSIGNMENT = "BRANCH_ASSIGNMENT",
  COMPENSATION_ADVISOR = "COMPENSATION_ADVISOR",
  CONTROL_PERSON = "CONTROL_PERSON",
  CUSTODIAN = "CUSTODIAN",
  DECEDENT = "DECEDENT",
  DEPENDENT = "DEPENDENT",
  EMPLOYEE = "EMPLOYEE",
  FINANCIAL_ADVISER = "FINANCIAL_ADVISER",
  FINANCIAL_ADVISOR = "FINANCIAL_ADVISOR",
  FINANCIAL_ADVISOR_PRIMARY = "FINANCIAL_ADVISOR_PRIMARY",
  HOUSEHOLD_MEMBER = "HOUSEHOLD_MEMBER",
  OWNER = "OWNER",
  OWNER_JOINT = "OWNER_JOINT",
  OWNER_MINOR = "OWNER_MINOR",
  OWNER_TRUST = "OWNER_TRUST",
  POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
  POWER_OF_ATTORNEY_READ_ONLY = "POWER_OF_ATTORNEY_READ_ONLY",
  PRIMARY_ADVISOR = "PRIMARY_ADVISOR",
  PROTECTOR = "PROTECTOR",
  SETTLOR = "SETTLOR",
  TRUST = "TRUST",
  TRUSTED_CONTACT = "TRUSTED_CONTACT",
  TRUSTEE = "TRUSTEE",
  TRUSTEE_AND_SETTLOR = "TRUSTEE_AND_SETTLOR",
  UNKNOWN_RELATIONSHIP_NAME = "UNKNOWN_RELATIONSHIP_NAME",
}

export enum RelationshipSubtype {
  AUNT = "AUNT",
  BROTHER = "BROTHER",
  CHARITY = "CHARITY",
  CHILD = "CHILD",
  DAUGHTER = "DAUGHTER",
  DOMESTIC_PARTNER = "DOMESTIC_PARTNER",
  FATHER = "FATHER",
  FATHER_IN_LAW = "FATHER_IN_LAW",
  FRIEND = "FRIEND",
  GRANDDAUGHTER = "GRANDDAUGHTER",
  GRANDFATHER = "GRANDFATHER",
  GRANDMOTHER = "GRANDMOTHER",
  GRANDSON = "GRANDSON",
  MINOR = "MINOR",
  MOTHER = "MOTHER",
  MOTHER_IN_LAW = "MOTHER_IN_LAW",
  NEIGHBOR = "NEIGHBOR",
  NEPHEW = "NEPHEW",
  NIECE = "NIECE",
  NON_SPOUSE = "NON_SPOUSE",
  OTHER = "OTHER",
  SISTER = "SISTER",
  SON = "SON",
  SPOUSE = "SPOUSE",
  TRUST = "TRUST",
  UNCLE = "UNCLE",
  UNKNOWN = "UNKNOWN",
}

export enum RetakeSources {
  RETAKE_PRODUCT_SELECTION = "RETAKE_PRODUCT_SELECTION",
  RETAKE_QUESTIONNAIRE = "RETAKE_QUESTIONNAIRE",
  RETAKE_QUESTIONNAIRE_PRODUCT_SELECTION = "RETAKE_QUESTIONNAIRE_PRODUCT_SELECTION",
  SWITCH_PRODUCT_SELECTION = "SWITCH_PRODUCT_SELECTION",
  SWITCH_QUESTIONNAIRE = "SWITCH_QUESTIONNAIRE",
  SWITCH_QUESTIONNAIRE_PRODUCT_SELECTION = "SWITCH_QUESTIONNAIRE_PRODUCT_SELECTION",
}

export enum ReviewCompletionType {
  CLIENT_DECLINED = "CLIENT_DECLINED",
  CLIENT_IGNORED = "CLIENT_IGNORED",
  CLIENT_UNREACHABLE = "CLIENT_UNREACHABLE",
  CONDUCTED = "CONDUCTED",
  UNKNOWN = "UNKNOWN",
}

export enum ScheduledTransferStatus {
  ACTIVE = "ACTIVE",
  ACTIVE_AT_BROKERAGE = "ACTIVE_AT_BROKERAGE",
  ATTEMPTING_TO_SUBMIT_TO_BROKERAGE = "ATTEMPTING_TO_SUBMIT_TO_BROKERAGE",
  CANCELLED = "CANCELLED",
  CASH_GENERATED = "CASH_GENERATED",
  COMPLETED = "COMPLETED",
  GENERAL_ERROR = "GENERAL_ERROR",
  INSUFFICIENT_ASSETS = "INSUFFICIENT_ASSETS",
  PENDING = "PENDING",
  PENDING_CANCELLATION = "PENDING_CANCELLATION",
  SUBMITTED = "SUBMITTED",
  TEST = "TEST",
  TRADES_SUBMITTED = "TRADES_SUBMITTED",
  UNAUTHORIZED = "UNAUTHORIZED",
}

export enum SigningDocumentsField {
  CREATED = "CREATED",
}

export enum SleeveType {
  CASH = "CASH",
  INVESTMENT = "INVESTMENT",
}

export enum SortOrder {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export enum SourceOfFundsType {
  ASSET_APPRECIATION = "ASSET_APPRECIATION",
  BUSINESS_REVENUE = "BUSINESS_REVENUE",
  EARNINGS = "EARNINGS",
  GIFTS = "GIFTS",
  INHERITANCE = "INHERITANCE",
  INVESTMENT_PROCEEDS = "INVESTMENT_PROCEEDS",
  LEGAL_OR_INSURANCE = "LEGAL_OR_INSURANCE",
  LEGAL_SETTLEMENT = "LEGAL_SETTLEMENT",
  OTHER = "OTHER",
  PENSION_OR_IRA_OR_RETIREMENT_SAVINGS = "PENSION_OR_IRA_OR_RETIREMENT_SAVINGS",
  ROLLOVER = "ROLLOVER",
  SALE_OF_ASSETS = "SALE_OF_ASSETS",
  SALE_OF_BUSINESS = "SALE_OF_BUSINESS",
  SALE_OF_PRIMARY_REALESTATE = "SALE_OF_PRIMARY_REALESTATE",
  SAVINGS_FROM_EARNING = "SAVINGS_FROM_EARNING",
}

export enum SourceStatusTypes {
  AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR",
  MFA_ERROR = "MFA_ERROR",
  SIGFIG_ERROR = "SIGFIG_ERROR",
  SUCCESS = "SUCCESS",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

export enum SurrenderType {
  FULL_ANNUITY_TRANSFER = "FULL_ANNUITY_TRANSFER",
  PARTIAL_ANNUITY_TRANSFER = "PARTIAL_ANNUITY_TRANSFER",
  PENALTY_FREE_SURRENDER = "PENALTY_FREE_SURRENDER",
}

export enum SuspensionType {
  PARTNER = "PARTNER",
  TRADER = "TRADER",
}

export enum TaxStatus {
  CERTIFIED_NO_WITHHOLDING = "CERTIFIED_NO_WITHHOLDING",
  EXEMPT_ACCOUNT = "EXEMPT_ACCOUNT",
  GOVT_MANDATED_WITHHOLDING = "GOVT_MANDATED_WITHHOLDING",
  NOT_CERTIFIED_WITHHOLD = "NOT_CERTIFIED_WITHHOLD",
  UNKNOWN = "UNKNOWN",
  W9_CERTIFIED_NO_WITHHOLDING = "W9_CERTIFIED_NO_WITHHOLDING",
}

export enum TransferFrequency {
  BIMONTHLY = "BIMONTHLY",
  BIWEEKLY = "BIWEEKLY",
  MONTHLY = "MONTHLY",
  ONE_TIME = "ONE_TIME",
  WEEKLY = "WEEKLY",
}

export enum TransferFrequencyType {
  ONE_TIME = "ONE_TIME",
  RECURRING = "RECURRING",
}

export enum TransferMethod {
  ACAT = "ACAT",
  INTERNAL = "INTERNAL",
}

export enum TransferMethodSubType {
  DEFAULT = "DEFAULT",
  JOURNAL = "JOURNAL",
}

export enum TransferType {
  DEPOSIT = "DEPOSIT",
  WITHDRAWAL = "WITHDRAWAL",
}

export enum TrustIdentificationType {
  SSN = "SSN",
  TIN = "TIN",
}

export enum TrustProfileType {
  NON_TRUSTEE_GRANTOR = "NON_TRUSTEE_GRANTOR",
  TRUST = "TRUST",
  TRUSTEE = "TRUSTEE",
}

export enum TrustType {
  IRREVOCABLE = "IRREVOCABLE",
  REVOCABLE = "REVOCABLE",
}

export enum UpdateWorkflowStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  DOCS_PREPARED = "DOCS_PREPARED",
  DOCS_SIGNED = "DOCS_SIGNED",
  PENDING = "PENDING",
  SIGNATURE_FAILED = "SIGNATURE_FAILED",
  STAGED = "STAGED",
}

export enum UserNoteEntityType {
  ASSET_DEPOSIT = "ASSET_DEPOSIT",
  BANK_ACCOUNT_ASSOCIATION_VERIFICATION_REQUEST = "BANK_ACCOUNT_ASSOCIATION_VERIFICATION_REQUEST",
  CASH_DEPOSIT = "CASH_DEPOSIT",
  CASH_WITHDRAWAL = "CASH_WITHDRAWAL",
  CLOSE_WORKFLOW = "CLOSE_WORKFLOW",
  COPILOT_PORTFOLIO = "COPILOT_PORTFOLIO",
  FAILURE = "FAILURE",
  FUNDING_REVIEW = "FUNDING_REVIEW",
  RECURRING_CASH_DEPOSIT = "RECURRING_CASH_DEPOSIT",
  RECURRING_CASH_WITHDRAWAL = "RECURRING_CASH_WITHDRAWAL",
  SIGNING_DOCUMENT = "SIGNING_DOCUMENT",
  TRADING_SUSPENSION = "TRADING_SUSPENSION",
}

export interface AddressInput {
  addressLine1?: string | null;
  addressLine2?: string | null;
  countryCode?: string | null;
  countryPrimarySubdivision?: string | null;
  countrySecondarySubdivision?: string | null;
  postalCode?: string | null;
  type: AddressType;
}

export interface AdvisorMetricSortInput {
  metricId: string;
  order: OrderType;
}

export interface AssetDepositsSortInput {
  field: AssetDepositField;
  order: OrderType;
}

export interface AssetTransferAttributesInput {
  assetFundingOptionId?: string | null;
  assetTransferTransactionType?: AssetTransferTransactionType | null;
  dataSourceCode?: string | null;
  isSwitchFormRequired?: boolean | null;
  morningstarProposalId?: string | null;
  skipCreationAtBrokerage?: boolean | null;
}

export interface AssociatedEntityInput {
  entityId: string;
  entityType: AssociatedEntityType;
}

export interface BalanceInput {
  balance: MoneyInput;
  type: BalanceType;
}

export interface BankAccountInput {
  accountNumber: string;
  attributes?: StringAttributesInput[] | null;
  financialInstitution: string;
  isSynced?: boolean | null;
  nameOnBankAccount: string;
  productName?: string | null;
  routingNumber: string;
  type: BankAccountType;
}

export interface BankAccountInputWithParty {
  bankAccount: BankAccountInput;
  partyId: string;
}

export interface BusinessEntityAttributesInput {
  accountHolderType?: AccountHolderType[] | null;
  countryOfTaxation?: string | null;
  organizationDocumentName?: string | null;
  resolutionDateAdopted?: SymphonyDate | null;
  roleType?: (BusinessEntityRoleType | null)[] | null;
  taxation?: string | null;
  trustDate?: SymphonyDate | null;
}

export interface BusinessEntityInput {
  attributes?: BusinessEntityAttributesInput | null;
  country?: string | null;
  name?: string | null;
  state?: string | null;
}

export interface CancelTransferInput {
  brokerageTransferId?: string | null;
  financialAccountId: string;
  frequency: TransferFrequency;
  pin?: string | null;
  transferId: string;
  type: TransferType;
}

export interface ClientDocumentListPaginationInput {
  limit?: number | null;
  offset?: OffsetInput[] | null;
}

export interface CloseWorkflowsSortInput {
  field: CloseWorkflowField;
  order: OrderType;
}

export interface CompanyInput {
  designation?: string | null;
  organizationName?: string | null;
}

export interface ComputeRecommendedPortfolioAllocationInput {
  modelPortfolioInternalId: number;
  modelPortfolioSeriesId: number;
  percentage: SymphonyDecimal;
}

export interface ContentStackEntryInput {
  contentTypeId: string;
  entryId: string;
  environment?: string | null;
  token?: string | null;
}

export interface CreateAnnuityTransferInput {
  accountNumber: string;
  amount: SymphonyDecimal;
  deliveryFirm: DeliveryFirmInput;
  fundingSourceIndex?: number | null;
  managedProductId: string;
  surrenderType: SurrenderType;
}

export interface CreateAssetTransferInput {
  attributes?: AssetTransferAttributesInput | null;
  cashAmount?: SymphonyDecimal | null;
  destinationFinancialAccountId: string;
  fundingSourceIndex?: number | null;
  isFullTransfer?: boolean | null;
  isInitial?: boolean | null;
  method: TransferMethod;
  methodSubType?: TransferMethodSubType | null;
  notes?: string | null;
  positionTransfer: PositionTransferInput;
  sourceFinancialAccountAccountNumber?: string | null;
  sourceFinancialAccountBrokerageAlias?: string | null;
  sourceFinancialAccountBrokerageName?: string | null;
  sourceFinancialAccountId?: string | null;
  sourceFinancialAccountType: FinancialAccountType;
  sourcePortfolioBrokerageDtcNumber?: string | null;
  type: TransferType;
}

export interface CreateCashTransferInput {
  bankAccountWithParty?: BankAccountInputWithParty | null;
  cashAmount: SymphonyDecimal;
  contributionYear?: number | null;
  day?: number | null;
  destinationFinancialAccountId?: string | null;
  distributionReason?: string | null;
  federalTaxWithholdingCode?: string | null;
  federalTaxWithholdingPercentage?: SymphonyDecimal | null;
  frequency: TransferFrequency;
  fundingSourceIndex?: number | null;
  notes?: string | null;
  numberOfOccurrences?: number | null;
  scheduledAt?: SymphonyDateTime | null;
  sleeve: SleeveType;
  sourceFinancialAccountId?: string | null;
  stateTaxWithholdingCode?: string | null;
  stateTaxWithholdingPercentage?: SymphonyDecimal | null;
  transactionId?: string | null;
  type: TransferType;
  withdrawalGrossUp?: boolean | null;
}

/**
 * How many records to skip and the total records to return
 */
export interface CursorPaginationInput {
  cursor?: string | null;
  pageSize: number;
}

export interface DataPointInput {
  dataPointKey: string;
  type: DataPointType;
  values: DataPointValueInput[];
}

export interface DataPointValueInput {
  value: string;
  valueText?: string | null;
}

export interface DeliveryFirmInput {
  address: AddressInput;
  name: string;
  phoneNumber: string;
}

export interface FinancialAccountAssociationInput {
  bankAccount?: BankAccountInput | null;
  bankAccountId?: string | null;
}

export interface FundingReviewsSortInput {
  field: FundingReviewSortField;
  order: OrderType;
}

export interface InvestmentAmountsInput {
  nonQualified?: string | null;
  percentage?: number | null;
  qualified?: string | null;
}

export interface InvestmentExperienceDetailInput {
  alternativeInvestments: InvestmentAmountsInput;
  annuitiesAndLifeInsurance: InvestmentAmountsInput;
  bonds: InvestmentAmountsInput;
  foreignCurrency: InvestmentAmountsInput;
  foreignSecurity: InvestmentAmountsInput;
  lifeInsurance: InvestmentAmountsInput;
  limitedPartnerships: InvestmentAmountsInput;
  mutualFunds: InvestmentAmountsInput;
  options: InvestmentAmountsInput;
  other: InvestmentAmountsInput;
  otherAssetClass?: string | null;
  securityFutures: InvestmentAmountsInput;
  shortTerms: InvestmentAmountsInput;
  stocks: InvestmentAmountsInput;
  total: InvestmentAmountsInput;
  variableContracts: InvestmentAmountsInput;
}

export interface InvestmentInput {
  accountPurpose?: string | null;
  experienceBuyingOrSellingAnnuitiesAndLifeInsurance?: string | null;
  experienceBuyingOrSellingBonds?: string | null;
  experienceBuyingOrSellingMutualFunds?: string | null;
  experienceBuyingOrSellingOptions?: string | null;
  experienceBuyingOrSellingOther?: string | null;
  experienceBuyingOrSellingStocks?: string | null;
  experienceBuyingOrSellingVariableContracts?: string | null;
  experienceDetail?: InvestmentExperienceDetailInput | null;
  hasExperienceBuyingOrSellingInstruments?: boolean | null;
  investmentExperience?: string | null;
  investmentHorizon?: string | null;
  investmentObjective?: string | null;
}

export interface LiveRateInput {
  certificateNumber?: string | null;
  customerInitiated: boolean;
  gicType: string;
  interestBasis: number;
  interestFrequency: InterestFrequency;
  isCompoundRate?: string | null;
  issuer: string;
  maturityDate: SymphonyDateTime;
  pricingRequestId: number;
  rates: LiveRateRatesInput;
  securityId: string;
  strikeDate?: SymphonyDateTime | null;
  term: LiveRateTermsInput;
}

export interface LiveRateRatesInput {
  interimRate?: SymphonyDecimal | null;
  maximumRate?: SymphonyDecimal | null;
  targetRate: SymphonyDecimal;
}

export interface LiveRateTermsInput {
  displayPeriod: number;
  displayUnit: RateUnitType;
  end?: number | null;
  start?: number | null;
  unit?: RateUnitType | null;
}

/**
 * Managed Product Additional Attributes
 */
export interface ManagedProductAdditionalAttributesInput {
  ageOfTermination?: number | null;
  assetConsolidationAccountIdentifier?: string | null;
  uploadedFilePath?: string | null;
}

export interface ManagedProductsSortInput {
  field: ManagedProductsField;
  order: OrderType;
}

export interface MetricValueFilterInput {
  max: SymphonyDecimal;
  metricId: string;
  min: SymphonyDecimal;
}

export interface ModelPortfolioAllocationInput {
  modelPortfolioInternalId: number;
  modelPortfolioSeriesId: number;
  percentage?: SymphonyDecimal | null;
}

export interface ModelPortfolioInput {
  allocationPercentage: SymphonyDecimal;
  modelPortfolioInternalId: number;
  seriesId: number;
}

export interface MoneyInput {
  currencyCode: string;
  value: SymphonyDecimal;
}

export interface OffsetInput {
  name: string;
  value: number;
}

/**
 * How many records to skip and the total records to return
 */
export interface PaginationInput {
  limit: number;
  offset: number;
}

export interface PaperworkAdditionalAttributesInput {
  addGoldmanSachSlcSblDisclosure?: string | null;
  coApplicantRelationshipType?: string | null;
  costBasisDisposalMethod?: string | null;
  dividendDistributionIncome?: string | null;
  eDeliveryEmailAddress?: string | null;
  enrollInEDelivery?: string | null;
  entityProfileType?: EntityProfileType | null;
  formCrsDeliveryDate?: SymphonyDate | null;
  formCrsDeliveryMethod?: string | null;
  isFormCrsReviewed?: boolean | null;
  otherIssuingAgency?: string | null;
  ownershipPercentage?: SymphonyDecimal | null;
  pledgedAccountNumbers?: (string | null)[] | null;
  proceeds?: string | null;
  receiveAssetMovementAuthorizationForm?: string | null;
  receiveConfirmsViaEmail?: boolean | null;
  receiveCustomerCorrespondenceViaEmail?: boolean | null;
  receiveShareholderDocumentsViaEmail?: boolean | null;
  receiveStatementsViaEmail?: boolean | null;
  receiveTaxDocumentsViaEmail?: boolean | null;
  referralSource?: string | null;
  repCode?: string | null;
  securityPurchases?: string | null;
  selectContact?: string | null;
  selectedContactPartyId?: string | null;
  tenantsInCommonPercentage?: string | null;
}

export interface PaperworkInput {
  additionalAttributes?: PaperworkAdditionalAttributesInput | null;
  id?: string | null;
  investment?: InvestmentInput | null;
  isHomeAddressDerivedFromPrimary?: boolean | null;
  isMailingAddressDerivedFromPrimary?: boolean | null;
  isMailingAddressSameAsHomeAddress?: boolean | null;
  isProxyVotingEnabled?: boolean | null;
  managedId: string;
  paperworkFreeFormId?: string | null;
  paperworkType: PaperworkType;
  party: PartyInput;
  regulatoryInformation?: RegulatoryInformationInput | null;
  relationships?: RelationshipInput[] | null;
  tradeConfirmationFrequency?: string | null;
  trustInformation?: TrustInformationInput | null;
  wealthInformation?: WealthInput | null;
}

export interface PartyContactInput {
  contact: string;
  isPrimary?: boolean | null;
  type: ContactType;
}

export interface PartyIdentifierInput {
  identifierCountry?: string | null;
  identifierExpiry?: SymphonyDate | null;
  identifierIssuance?: SymphonyDate | null;
  identifierJurisdiction?: string | null;
  identifierValue?: string | null;
  setValueToNull?: boolean | null;
  type: IdentifierType;
}

export interface PartyInput {
  addresses?: AddressInput[] | null;
  id?: string | null;
  identifiers?: PartyIdentifierInput[] | null;
  partyBusinessEntity?: BusinessEntityInput | null;
  partyCompany?: CompanyInput | null;
  partyContacts?: PartyContactInput[] | null;
  partyPerson?: PartyPersonInput | null;
  relationships?: RelationshipInput[] | null;
}

export interface PartyPersonInput {
  birthDate?: SymphonyDate | null;
  citizenship?: string | null;
  deathDate?: SymphonyDate | null;
  employmentStatus?: EmploymentStatus | null;
  familyName?: string | null;
  givenName?: string | null;
  language?: PartyPersonLanguageInput | null;
  maritalStatus?: MaritalStatus | null;
  middleName?: string | null;
  occupation?: string | null;
  residentType?: number | null;
  taxStatus?: TaxStatus | null;
}

export interface PartyPersonLanguageInput {
  isEnglishPrimaryLanguage?: string | null;
  otherPrimaryLanguage?: string | null;
  primaryLanguage?: string | null;
}

/**
 * The parameters used to calculate account performance
 */
export interface PerformanceMeasurementInput {
  from: SymphonyDate;
  name?: string | null;
  performanceMethod?: PerformanceMethodTypes | null;
  to: SymphonyDate;
}

export interface PlaidAccountMetadata {
  id: string;
  mask?: string | null;
  name: string;
  type: string;
}

export interface PlaidInstitutionMetadata {
  institutionId: string;
  name?: string | null;
}

export interface PlaidMetadata {
  accounts: PlaidAccountMetadata[];
  institution: PlaidInstitutionMetadata;
}

export interface PositionInput {
  attributes?: PositionsAttributesInput | null;
  fromTypeCode?: string | null;
  identifiers: StringAttributesInput[];
  quantities: QuantityInput[];
  toTypeCode?: string | null;
}

export interface PositionTransferInput {
  estimatedValue?: SymphonyDecimal | null;
  positions?: PositionInput[] | null;
}

export interface PositionsAttributesInput {
  brokerOrDealer?: string | null;
  estimatedNetRedemptionValue?: SymphonyDecimal | null;
  estimatedSalesChargeAmount?: SymphonyDecimal | null;
  expenseRatio?: SymphonyDecimal | null;
  isManualSecurity?: boolean | null;
  mostRecentPurchaseDate?: SymphonyDate | null;
  portfolioAssetClassBlend?: string | null;
  shareClass?: string | null;
}

export interface QuantityInput {
  marketValue?: MoneyInput | null;
  type: QuantityType;
  units: SymphonyDecimal;
}

export interface QuestionStepInput {
  dataPoints: DataPointInput[];
  questionKey: string;
  questionText: string;
}

export interface QuestionnaireEntityInput {
  entityId: string;
  entityType: QuestionnaireEntityType;
}

export interface RegulatoryInformationInput {
  finraRelationship?: RegulatoryRelationship | null;
  ticker?: string | null;
  phcRelationship?: RegulatoryRelationship | null;
  anotherBrokerEmployeeName?: string | null;
  isPartnerEmployee?: boolean | null;
  nameOfSeniorPoliticalFigure?: string | null;
  isAssociatedWithOfacOrSdn?: boolean | null;
  relationship?: string | null;
  isDiplomaticEmployee?: boolean | null;
  marijuanaBusinessesParticipation?: boolean | null;
  anotherBrokerName?: string | null;
  isExchangeEmployed?: boolean | null;
  isPhcSeniorOfficer?: boolean | null;
  relatedEmployeeBrokerName?: string | null;
  exchangeAffiliationType?: string | null;
  countryOfOffice?: string | null;
  hasPartnerEmployeeConnection?: boolean | null;
  relatedToAnotherBrokerEmployee?: boolean | null;
  controlType?: ControlType | null;
  isEmployedByAccountBroker?: boolean | null;
  isAccreditedInvestor?: boolean | null;
  isGovernmentEmployee?: boolean | null;
  relatedPartnerEmployeeRelationship?: string | null;
  relatedPartnerEmployeeName?: string | null;
  anotherBrokerEmployeeRelationship?: string | null;
  isForeignGovernmentEmployee?: boolean | null;
  publiclyHeldCompanyName?: string | null;
  exchangeEmployerName?: string | null;
  relatedEmployeeAnotherBrokerName?: string | null;
  hasSpfConnection?: boolean | null;
  hasAffiliationLetter?: boolean | null;
  exchangeAddress?: AddressInput | null;
  gamblingIndustryParticipation?: boolean | null;
  isAssociatedWithUsRegisteredInvestmentAdvisor?: boolean | null;
  politicalTitle?: string | null;
  isEmployedByAnotherBroker?: boolean | null;
}

export interface RelationshipInput {
  id?: string | null;
  isPerStirpes?: boolean | null;
  name: RelationshipName;
  nameOfTrustees?: string | null;
  order?: number | null;
  party: PartyInput;
  percentage?: SymphonyDecimal | null;
  relationshipType?: RelationshipSubtype | null;
}

export interface RepCodeInput {
  isPrimary: boolean;
  percentage: SymphonyDecimal;
  repCode: string;
}

export interface RequestCloseInput {
  closeBrokerageAccount: boolean;
  comments?: string | null;
  distributionReason?: string | null;
  federalTaxWithholdingCode?: string | null;
  federalTaxWithholdingPercentage?: SymphonyDecimal | null;
  managedAccountId: string;
  otherReason?: string | null;
  reason: CloseReason;
  scheduledAt?: SymphonyDateTime | null;
  sellAssets: boolean;
  stateTaxWithholdingCode?: string | null;
  stateTaxWithholdingPercentage?: SymphonyDecimal | null;
  transactionId?: string | null;
  transferToFinancialAccountId?: string | null;
  withdrawalGrossUp?: boolean | null;
}

/**
 * The parameters used to select a product using a sleeve
 */
export interface SelectedModelPortfolioInput {
  liveRates?: LiveRateInput[] | null;
  modelPortfolioInternalId: number;
  modelPortfolioSeriesId: number;
  percentage?: SymphonyDecimal | null;
  sleeveType: SleeveType;
}

export interface SortInput {
  field: Field;
  order: OrderType;
}

export interface StringAttributesInput {
  name: string;
  value: string;
}

export interface TradingSuspensionFilter {
  suspensionTags?: (string | null)[] | null;
  suspensionTypes?: (SuspensionType | null)[] | null;
}

export interface TrustInformationInput {
  canTrusteesModifyTrust?: string | null;
  eachTrusteeAction?: string | null;
  isTrusteeGrantor?: boolean | null;
  numberOfTrustees?: number | null;
  otherSettlorTrustorOrGrantor?: string | null;
  otherTrusteeAction?: string | null;
  revokerFirstName?: string | null;
  revokerLastName?: string | null;
  revokerMiddleName?: string | null;
  settlorTrustorOrGrantor?: string | null;
  trustAgreementDate?: SymphonyDate | null;
  trustGoverningState?: string | null;
  trustIdentificationNumber?: string | null;
  trustIdentificationType?: TrustIdentificationType | null;
  trustName?: string | null;
  trustProfileType: TrustProfileType;
  trustType?: TrustType | null;
  whoCanModifyTrust?: string | null;
}

export interface UpdateBankAccountInput {
  accountNumber: string;
  financialInstitution: string;
  nameOnBankAccount: string;
  routingNumber: string;
  type: BankAccountType;
}

export interface UpdateFlagInput {
  id: number;
  status: FlagStatus;
}

export interface UpdatePlanInput {
  accept?: boolean | null;
  managedId?: string | null;
  modelPortfolioIdentifier?: number | null;
  modelPortfolioSeriesId?: number | null;
  planId?: string | null;
  planUpdateWorkflowId?: string | null;
}

export interface UpdateTransferInput {
  brokerageTransferId?: string | null;
  financialAccountId: string;
  frequency: TransferFrequency;
  pin?: string | null;
  status: ScheduledTransferStatus;
  transferId: string;
  type: TransferType;
}

export interface ValidateCashTransferInput {
  accountType: FinancialAccountType;
  bankAccountId: string;
  cashAmount: SymphonyDecimal;
  financialAccountId: string;
  frequency: TransferFrequency;
  managedId: string;
  scheduledAt?: SymphonyDate | null;
  type: TransferType;
}

export interface WealthInput {
  annualIncome?: string | null;
  annualRecurringExpenses?: string | null;
  cash?: string | null;
  hasEmergencyFunds?: boolean | null;
  liabilities?: string | null;
  liquidAssets?: string | null;
  netWorth?: string | null;
  nonLiquidAssets?: string | null;
  numberOfDependents?: number | null;
  otherSourceOfInitialFunds?: string | null;
  sourceOfInitialFunds?: string | null;
  specialExpenses?: string | null;
  taxBracket?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
