import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import React, { ComponentProps } from 'react';

type LinkProps<C extends React.ElementType> = ComponentProps<typeof MuiLink> & MuiLinkProps<C, { component?: C }>;

export const Link: React.ForwardRefExoticComponent<
  LinkProps<any> & React.RefAttributes<HTMLAnchorElement>
> = React.forwardRef(
  ({ component, href, sx, ...rest }: LinkProps<any>, ref?: React.ForwardedRef<HTMLAnchorElement>) => {
    // Link component without href is not focusable via keyboard navigation.
    // For Links with onClick, Link component must be rendered as button component with role="link".
    // The advantage of using Link over Button is that Link's variant can be used to set Typography for styling.
    const buttonLinkProps = href ? { component } : { component: component ?? 'button', role: 'link' };
    return <MuiLink href={href} {...rest} {...buttonLinkProps} ref={ref} sx={{ ...sx, cursor: 'pointer' }} />;
  },
);
