import {
  GetErrorMessagesContent,
  GetErrorMessagesContent_all_error_messages_items,
  GetErrorMessagesContentVariables,
} from './__generated__/GetErrorMessagesContent';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type ErrorMessages = GetErrorMessagesContent_all_error_messages_items;

export const useGetErrorMessagesContent = (
  options?: QueryHookOptions<GetErrorMessagesContent, GetErrorMessagesContentVariables>,
): QueryResult<GetErrorMessagesContent, GetErrorMessagesContentVariables> => {
  return useContentstackQuery(queries.GetErrorMessagesContent, options);
};

export { getMockErrorMessageContent } from './mocks';
