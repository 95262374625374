import { Link, useTheme } from '@mui/material';
import React, { ComponentProps, FC, RefObject } from 'react';

export interface Props extends Omit<ComponentProps<typeof Link>, 'ref'> {
  dataQa?: string;
  ref?: RefObject<HTMLButtonElement>;
}

export const ModalLink: FC<Props> = ({ dataQa = 'modal-link', ref, underline = 'none', ...linkProps }) => {
  const {
    sfModalLink: { styles, typographyVariants },
  } = useTheme();
  return (
    <Link
      aria-haspopup="true"
      component="button"
      data-qa={`${dataQa}-link`}
      ref={ref}
      sx={{ ...styles }}
      underline={underline}
      variant={typographyVariants?.link}
      {...linkProps}
    />
  );
};
