import {
  GetOpsDashboardHeaderContentV2,
  GetOpsDashboardHeaderContentV2Variables,
} from './__generated__/GetOpsDashboardHeaderContentV2';
import * as queries from './query.gql';

import { useContentstackQuery } from '~/utils';
import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';

export const useGetOpsDashboardHeaderContentV2 = (
  options?: QueryHookOptions<GetOpsDashboardHeaderContentV2, GetOpsDashboardHeaderContentV2Variables>,
): QueryResult<GetOpsDashboardHeaderContentV2, GetOpsDashboardHeaderContentV2Variables> => {
  return useContentstackQuery(queries.GetOpsDashboardHeaderContentV2, options);
};
